<!--
 * @Description: E3与金蝶对账表
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="客户开票名称"
                title="客户开票名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="统计时间段"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="统计时间段"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="统计时间（始）"
                end-placeholder="统计时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getAbnormalTaskList } from "@/api";
export default {
  name: "abnormalOrder",
  data() {
    return {
      searchForm: {
        taskNumber: "", //任务单号
        expectTime: [], //要求完成时间
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "tagValue",
          display: true,
          fieldLabel: "客户开票名称",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "E3管理应收期初",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "E3权责费用",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpAttrName",
          display: true,
          fieldLabel: "E3预出费用",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehujiancheng",
          display: true,
          fieldLabel: "E3权责冲销",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "E3预出已冲销",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "预出未冲销",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "E3权责应收",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "unassigned",
          display: true,
          fieldLabel: "E3回款账户余额期初",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nunassigned",
          display: true,
          fieldLabel: "当期回款总额",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAssig",
          display: true,
          fieldLabel: "当期权责冲销",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAccept",
          display: true,
          fieldLabel: "当期预出已冲销",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "E3回款账户余额",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workIng",
          display: true,
          fieldLabel: "E3权责应收-E3预出已冲销-E3回款账户余额",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "金蝶应收",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "normalFinished",
          display: true,
          fieldLabel: "金蝶预收余额",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "金蝶应收-金蝶预收余额",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expecttimeStart = val[0] || "";
        this.searchForm.expecttimeend = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getAbnormalTaskList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
